var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.is_mounted,
          expression: "is_mounted",
        },
      ],
    },
    [
      _c("div", { attrs: { align: "center" } }, [
        _c("h4", { staticClass: "q-mb-sm" }, [_vm._v("Definisci Pratica")]),
        _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
          _vm._v(
            "Contratto " +
              _vm._s(_vm.IndiceProdotto + 1) +
              " di " +
              _vm._s(_vm.prodotti_selezionati.length) +
              " - " +
              _vm._s(_vm.proposta_attiva.label)
          ),
        ]),
        _c("br"),
        _c("hr"),
      ]),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-5 justify-center" },
          [
            _c("h5", [_vm._v("Allega PDF contratto")]),
            _c("q-uploader", {
              ref: "FilesAllegati",
              staticStyle: {
                width: "100%",
                "min-height": "350px",
                "box-shadow":
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              },
              attrs: {
                url: "https://localhost:1000",
                color: "teal",
                label: "Trascina QUI i documenti di questo Contratto",
                multiple: "",
                name: "upload_contratto",
                "hide-upload-btn": "",
                filter: _vm.checkFileType,
              },
              on: {
                rejected: function ($event) {
                  return _vm.onRejected()
                },
                removed: _vm.onRimuoviFileAllegati,
                added: _vm.onAggiungiFileAllegati,
              },
            }),
            _c("br"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-5 q-px-md" },
          [
            _c(
              "q-list",
              { attrs: { separator: "" } },
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Premio")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _c("h5", [
                            _vm._v(
                              _vm._s(_vm.getPremioCorrenteTotale()) + " Euro"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Provvigioni")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _c("h5", [
                            _vm._v(
                              _vm._s(_vm.getProvvigioniCorrentiTotali) + " Euro"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Compagnia")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(_vm.dati_contratto_originali.compagnia)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Fornitore")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(_vm.dati_contratto_originali.fornitore)
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Tariffa")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(_vm._s(_vm.dati_contratto_originali.tariffa)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Frazionamento")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(_vm.dati_contratto_originali.frazionamento)
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Decorrenza")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(_vm.dati_contratto_originali.decorrenza)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Scadenza")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(_vm._s(_vm.getScadenza())),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(
                  _vm.proposta_attiva.parametri,
                  function (garanzia, indexGaranzia) {
                    return _c(
                      "q-item",
                      _vm._b({ key: indexGaranzia }, "q-item", garanzia, false),
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _vm._v(_vm._s(garanzia.label)),
                            ]),
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(_vm._s(_vm.analizzaRisposta(garanzia))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-5 q-px-md" },
          [
            _c("h5", [_vm._v("Premi del Contratto")]),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .premio
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            type: "number",
                            outlined: "",
                            name: "premio_lordo",
                            label: "Premio lordo",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .premio,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "premio",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].premio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[0],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                0,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[0]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .provvigioni
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "provvigioni",
                            label: "Provvigioni",
                            step: "1",
                            readonly: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .provvigioni,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "provvigioni",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].provvigioni",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[1],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                1,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[1]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_terzi
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "diritti_terzi",
                            label: "Diritti di terzi",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_terzi,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "diritti_terzi",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].diritti_terzi",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[2],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                2,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[2]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_fornitore
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "diritti_fornitore",
                            label: "Diritti Fornitore",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_fornitore,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "diritti_fornitore",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].diritti_fornitore",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[3],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                3,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[3]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_emittente
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "diritti_emittente",
                            label: "Diritti Emittente",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_emittente,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "diritti_emittente",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].diritti_emittente",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[4],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                4,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[4]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_abybroker
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "diritti_aby",
                            label: "Diritti Aby Broker",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .diritti_abybroker,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "diritti_abybroker",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].diritti_abybroker",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[5],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                5,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[5]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .consulenza
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "consulenza",
                            label: "Consulenza",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .consulenza,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "consulenza",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].consulenza",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[6],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                6,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[6]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .costo_servizio
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "costo_servizio",
                            label: "Costo del servizio",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .costo_servizio,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "costo_servizio",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].costo_servizio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[7],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                7,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[7]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .quota_consulenza_unita_operativa
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "quota_consulenza_unita_operativa",
                            label: "Quota consulenza Unità Operativa",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .quota_consulenza_unita_operativa,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "quota_consulenza_unita_operativa",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].quota_consulenza_unita_operativa",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[8],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                8,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[8]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            error: _vm.isNotValidNumber(
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .quota_consulenza_aby
                            ),
                            "error-message":
                              "Il premio deve essere valorizzato con un valore numerico",
                            outlined: "",
                            name: "quota_consulenza_aby",
                            label: "Quota consulenza Aby Broker",
                            step: "1",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [_vm._v(" Euro ")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .quota_consulenza_aby,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ],
                                "quota_consulenza_aby",
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].quota_consulenza_aby",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      { staticClass: "q-ml-sm" },
                      [
                        _c("q-checkbox", {
                          attrs: { label: "Somma al premio" },
                          model: {
                            value:
                              _vm.dati_contratti_modificati[_vm.indice_prodotto]
                                .somma_al_premio[9],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_contratti_modificati[
                                  _vm.indice_prodotto
                                ].somma_al_premio,
                                9,
                                $$v
                              )
                            },
                            expression:
                              "dati_contratti_modificati[indice_prodotto].somma_al_premio[9]",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-5 q-px-md" },
          [
            _c("h5", [_vm._v("Dati del Contratto")]),
            _c("q-input", {
              attrs: {
                outlined: "",
                error: _vm.isNotValid(
                  "required|date",
                  _vm.dati_contratti_modificati[_vm.indice_prodotto].decorrenza
                ),
                "error-message": "Data mancante o non valida",
                mask: "##/##/####",
                name: "decorrenza",
                label: "Data di decorrenza contrattuale",
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c(
                        "q-icon",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { name: "event" },
                        },
                        [
                          _c(
                            "q-popup-proxy",
                            {
                              ref: "qDateProxy",
                              attrs: {
                                "transition-show": "scale",
                                "transition-hide": "scale",
                              },
                            },
                            [
                              _c(
                                "q-date",
                                {
                                  attrs: { mask: "DD/MM/YYYY" },
                                  model: {
                                    value:
                                      _vm.dati_contratti_modificati[
                                        _vm.indice_prodotto
                                      ].decorrenza,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dati_contratti_modificati[
                                          _vm.indice_prodotto
                                        ],
                                        "decorrenza",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dati_contratti_modificati[indice_prodotto].decorrenza",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row items-center justify-end",
                                    },
                                    [
                                      _c("q-btn", {
                                        directives: [
                                          {
                                            name: "close-popup",
                                            rawName: "v-close-popup",
                                          },
                                        ],
                                        attrs: { label: "Close", flat: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value:
                  _vm.dati_contratti_modificati[_vm.indice_prodotto].decorrenza,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.dati_contratti_modificati[_vm.indice_prodotto],
                    "decorrenza",
                    $$v
                  )
                },
                expression:
                  "dati_contratti_modificati[indice_prodotto].decorrenza",
              },
            }),
            _c("q-input", {
              attrs: {
                outlined: "",
                error: _vm.isNotValid("required|date", _vm.scadenza),
                "error-message": "Data mancante o non valida",
                mask: "##/##/####",
                name: "scadenza",
                label: "Data di scadenza",
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c(
                        "q-icon",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { name: "event" },
                        },
                        [
                          _c(
                            "q-popup-proxy",
                            {
                              ref: "qDateProxy",
                              attrs: {
                                "transition-show": "scale",
                                "transition-hide": "scale",
                              },
                            },
                            [
                              _c(
                                "q-date",
                                {
                                  attrs: { mask: "DD/MM/YYYY" },
                                  model: {
                                    value:
                                      _vm.dati_contratti_modificati[
                                        _vm.indice_prodotto
                                      ].scadenza,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dati_contratti_modificati[
                                          _vm.indice_prodotto
                                        ],
                                        "scadenza",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dati_contratti_modificati[indice_prodotto].scadenza",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row items-center justify-end",
                                    },
                                    [
                                      _c("q-btn", {
                                        directives: [
                                          {
                                            name: "close-popup",
                                            rawName: "v-close-popup",
                                          },
                                        ],
                                        attrs: { label: "Close", flat: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value:
                  _vm.dati_contratti_modificati[_vm.indice_prodotto].scadenza,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.dati_contratti_modificati[_vm.indice_prodotto],
                    "scadenza",
                    $$v
                  )
                },
                expression:
                  "dati_contratti_modificati[indice_prodotto].scadenza",
              },
            }),
            _c("q-input", {
              attrs: {
                outlined: "",
                error: _vm.isNotValid(
                  "required",
                  _vm.dati_contratti_modificati[_vm.indice_prodotto]
                    .numero_polizza
                ),
                "error-message":
                  "Il Numero di polizza della Compagnia deve essere inserito",
                label: "Numero di Polizza Compagnia",
              },
              model: {
                value:
                  _vm.dati_contratti_modificati[_vm.indice_prodotto]
                    .numero_polizza,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.dati_contratti_modificati[_vm.indice_prodotto],
                    "numero_polizza",
                    $$v
                  )
                },
                expression:
                  "dati_contratti_modificati[indice_prodotto].numero_polizza",
              },
            }),
            _vm.tariffa_esistente
              ? [
                  _c("q-select", {
                    attrs: {
                      outlined: "",
                      error: _vm.isNotValid(
                        "required",
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .compagnia
                      ),
                      "error-message": "La Compagnia deve essere valorizzata",
                      options: _vm.elenco_compagnie,
                      name: "compagnia",
                      label: "Compagnia",
                    },
                    model: {
                      value:
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .compagnia,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dati_contratti_modificati[_vm.indice_prodotto],
                          "compagnia",
                          $$v
                        )
                      },
                      expression:
                        "dati_contratti_modificati[indice_prodotto].compagnia",
                    },
                  }),
                  _c("q-select", {
                    attrs: {
                      outlined: "",
                      error: _vm.isNotValid(
                        "required",
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .tariffa
                      ),
                      "error-message": "La Tariffa deve essere valorizzata",
                      options: _vm.tariffe(
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .compagnia
                      ),
                      name: "tariffa",
                      label: "Tariffa",
                    },
                    model: {
                      value:
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .tariffa,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dati_contratti_modificati[_vm.indice_prodotto],
                          "tariffa",
                          $$v
                        )
                      },
                      expression:
                        "dati_contratti_modificati[indice_prodotto].tariffa",
                    },
                  }),
                  _c("q-input", {
                    attrs: {
                      outlined: "",
                      error: _vm.isNotValid("required", _vm.fornit),
                      "error-message": "Il Fornitore deve essere valorizzato",
                      label: "Fornitore",
                      readonly: "",
                    },
                    model: {
                      value: _vm.fornit,
                      callback: function ($$v) {
                        _vm.fornit = $$v
                      },
                      expression: "fornit",
                    },
                  }),
                ]
              : _vm._e(),
            !_vm.tariffa_esistente
              ? [
                  _c("q-select", {
                    staticStyle: { margin: "4px" },
                    attrs: {
                      outlined: "",
                      "error-message": "Il prodotto deve essere valorizzato",
                      options: _vm.elenco_prodotti,
                      label: "Prodotto a catalogo",
                      name: "prodotto_catalogo",
                      "virtual-scroll-slice-size": "0",
                      "virtual-scroll-item-size": "0",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onSelectProdotto()
                      },
                    },
                    model: {
                      value: _vm.prodotto_da_catalogo,
                      callback: function ($$v) {
                        _vm.prodotto_da_catalogo = $$v
                      },
                      expression: "prodotto_da_catalogo",
                    },
                  }),
                  _c("q-input", {
                    staticStyle: { margin: "4px" },
                    attrs: { outlined: "", label: "Compagnia", readonly: "" },
                    model: {
                      value:
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .compagnia,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dati_contratti_modificati[_vm.indice_prodotto],
                          "compagnia",
                          $$v
                        )
                      },
                      expression:
                        "dati_contratti_modificati[indice_prodotto].compagnia",
                    },
                  }),
                  _c("q-input", {
                    staticStyle: { margin: "4px" },
                    attrs: { outlined: "", label: "Tariffa", readonly: "" },
                    model: {
                      value:
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .tariffa,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dati_contratti_modificati[_vm.indice_prodotto],
                          "tariffa",
                          $$v
                        )
                      },
                      expression:
                        "dati_contratti_modificati[indice_prodotto].tariffa",
                    },
                  }),
                  _c("q-input", {
                    staticStyle: { margin: "4px" },
                    attrs: { outlined: "", label: "Fornitore", readonly: "" },
                    model: {
                      value:
                        _vm.dati_contratti_modificati[_vm.indice_prodotto]
                          .fornitore,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dati_contratti_modificati[_vm.indice_prodotto],
                          "fornitore",
                          $$v
                        )
                      },
                      expression:
                        "dati_contratti_modificati[indice_prodotto].fornitore",
                    },
                  }),
                ]
              : _vm._e(),
            _c("br"),
            _c("q-select", {
              staticStyle: { margin: "4px" },
              attrs: {
                outlined: "",
                "error-message":
                  "Devi definire se il contratto e` con Tacito Rinnovo oppure no",
                options: _vm.elenco_tacito_rinnovo,
                label: "TACITO RINNOVO",
                name: "tacito_rinnovo",
                error: _vm.isNotValid(
                  "required",
                  _vm.dati_contratti_modificati[_vm.indice_prodotto]
                    .tacito_rinnovo
                ),
                "emit-value": "",
                "map-options": "",
                "virtual-scroll-slice-size": "0",
                "virtual-scroll-item-size": "0",
              },
              model: {
                value:
                  _vm.dati_contratti_modificati[_vm.indice_prodotto]
                    .tacito_rinnovo,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.dati_contratti_modificati[_vm.indice_prodotto],
                    "tacito_rinnovo",
                    $$v
                  )
                },
                expression:
                  "dati_contratti_modificati[indice_prodotto].tacito_rinnovo",
              },
            }),
            _c("q-select", {
              staticStyle: { margin: "4px" },
              attrs: {
                outlined: "",
                "error-message":
                  "Devi definire se la quietanza richiede il Simplo oppure no",
                options: _vm.elenco_tacito_rinnovo,
                label: "ABILITA RCHIESTA SIMPLO",
                name: "abilita_richiesta_simplo",
                error: _vm.isNotValid(
                  "required",
                  _vm.dati_contratti_modificati[_vm.indice_prodotto]
                    .abilita_simplo
                ),
                "emit-value": "",
                "map-options": "",
                "virtual-scroll-slice-size": "0",
                "virtual-scroll-item-size": "0",
              },
              model: {
                value:
                  _vm.dati_contratti_modificati[_vm.indice_prodotto]
                    .abilita_simplo,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.dati_contratti_modificati[_vm.indice_prodotto],
                    "abilita_simplo",
                    $$v
                  )
                },
                expression:
                  "dati_contratti_modificati[indice_prodotto].abilita_simplo",
              },
            }),
            _c("br"),
            _c("h5", [_vm._v("Premio totale Contratto")]),
            _c("h3", { attrs: { id: "premio_totale_contratto" } }, [
              _vm._v(_vm._s(_vm.calcolaPremioTotale) + " Euro"),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "col-12 col-md-12 justify-center" }, [
          _c("br"),
          _c("hr"),
          _c("div", { staticClass: "col-12 col-md-10 row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "INDIETRO",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                    icon_align: "left",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: _vm.getLabelValue,
                    color: "blue-grey",
                    icon: _vm.getIconValue,
                    size: "md",
                    icon_align: "right",
                    disabled: !_vm.pulsante_abilitato,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onAvantiClicked()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }